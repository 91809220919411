export {createFrontendPCTopQuestionRow};

const monthNames = ['1','2','3','4','5','6','7','8','9','10','11','12'];
function formatDate(date) {
  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return year + '/' + monthNames[monthIndex] + '/' + day + ' ' + hours + ':' + ('0' + minutes).slice(-2);
}

function createFrontendPCTopQuestionRow(question, options) {
  const $div = document.createElement('div');
  $div.id = 'question-' + question.id;
  $div.classList.add('talk_list_article', 'element-creators-row');
  {
    const $a = document.createElement('a');
    $a.href = options.url.replace('000', question.id);
    {
      const $headline = document.createElement('p');
      $headline.classList.add('talk_headline');
      $headline.textContent = question.title;
      $a.appendChild($headline);
    }
    {
      const $text = document.createElement('div');
      $text.classList.add('talk_text', 'clamp2');
      $text.textContent = question.body;
      $a.appendChild($text);
    }
    {
      const $username = document.createElement('p');
      $username.classList.add('talk_username');
      $username.textContent = question.member_profile.nickname;
      $a.appendChild($username);
    }
    {
      const $status = document.createElement('p');
      $status.classList.add('talk_status');
      {
        const $span = document.createElement('span');
        $span.classList.add('talk_view_badge');
        $span.textContent = question.page_views_count + ' ';
        $status.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.classList.add('talk_star_badge');
        $span.textContent = question.comments_likes_count + ' ';
        $status.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.classList.add('talk_date');
        $span.textContent = formatDate(new Date(question.posted));
        $status.appendChild($span);
      }
      $a.appendChild($status);
    }
    $div.appendChild($a);
  }
  return $div;
}
