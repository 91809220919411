import createAPI from './APIRequest';
import {createQuestionRow} from "./elementCreators/createQuestionRow";
import {createAdviserRow} from "./elementCreators/createAdviserRow";
import {createFrontendPCTopQuestionRow} from "./elementCreators/createFrontendPCTopQuestionRow";
import {createFrontendSPTopQuestionRow} from "./elementCreators/createFrontendSPTopQuestionRow";

export {searchResources};

async function render(response, options) {
  const $viewers = document.querySelectorAll(options.viewerSelector);
  if ($viewers.length > 0) {
    const $nextViewers = document.querySelectorAll(options.viewerSelector + '-next');
    $viewers.forEach(($viewer, index) => {
      options.viewerSelector = null;
      options.viewer = $viewer;
      options.nextViewer = $nextViewers[index];
      render(response, options);
    });
    return;
  }
  let $viewer;
  let $nextViewer;
  if (typeof options.viewer === 'object') {
    $viewer = options.viewer;
    options.next && ($nextViewer = options.nextViewer);
  } else {
    $viewer = document.getElementById(options.viewer);
    options.next && ($nextViewer = document.getElementById(options.viewer + '-next'));
  }
  response.data && response.data.forEach(row => {
    if ($nextViewer && $viewer.querySelectorAll('.element-creators-row').length >= options.next) {
      $viewer = $nextViewer;
    }
    let $row = null;
    options.element === 'question' && ($row = createQuestionRow(row, options));
    options.element === 'frontendPCTopQuestion' && ($row = createFrontendPCTopQuestionRow(row, options));
    options.element === 'frontendSPTopQuestion' && ($row = createFrontendSPTopQuestionRow(row, options));
    options.element === 'adviser' && ($row = createAdviserRow(row, options));
    if ($row) {
      $viewer.appendChild($row);
      $row.dataset.originalSort = $viewer.querySelectorAll('.element-creators-row').length;
    }
  });
}

function _display(el, chunk_count) {
  const $loading = el.querySelectorAll('.is-loading');
  const $gtmNextPage = el.querySelector('.gtm-next-page'); // Google Tag Manager もっと見るPV計測用エレメント
  const $noResourcesBlock = el.querySelector('.no-resources');
  const $hidden = el.querySelectorAll('.is-hidden');
  const $helpful = el.querySelector('.box-helpful');
  if (chunk_count == 0) {
    $loading.forEach($l => {
      $l.classList.add('is-hidden');
    });
    (parseInt(el.dataset.resourcesPage) === 1) && $noResourcesBlock && $noResourcesBlock.classList.remove('is-hidden');
    el.appendChild(document.createElement('span')); // swiper更新させるためダミーエレメントを追加
  } else {
    $loading.forEach($l => {
      $l.parentNode.parentNode.classList.remove('bg-gray');
    });
    $hidden.forEach($h => {
      $h.classList.contains('no-resources') || $h.classList.remove('is-hidden');
    });
    el.dataset.resourcesPage = (parseInt(el.dataset.resourcesPage) + 1) + '';
    $gtmNextPage && ($gtmNextPage.href = location.pathname + '/more-' + el.dataset.resourcesPage);
  }
  if ($helpful && (!$noResourcesBlock.classList.contains('is-hidden'))) {
    $helpful.classList.add('is-hidden');
  }
  $loading.forEach($l => {
    $l.classList.remove('is-loading');
    $l.style.backgroundColor = null;
    $l.style.borderStyle = null;
    $l.style.height = null;
  });
  ($loading.length == 2) && $loading[0].remove();
}

function display(el, chunk_count) {
  if (el.dataset.displaySelector) {
    return document.querySelectorAll(el.dataset.displaySelector).forEach($d => _display($d, chunk_count));
  }
  _display(el, chunk_count);
}

function success(el, response) {
  render(response, JSON.parse(el.dataset.renderOptions));
  display(el, response.data.length);
}

const resourcesUrlCallCounts = {};
function _searchResources(el) {
  Array.prototype.slice.call(el.querySelectorAll('.starts-loading'), 0).forEach( el => {
    el.style.opacity = '0.0';
  });
  const resourcesUrl = el.dataset.resourcesUrl + '&limit=' + el.dataset.resourcesLimit + '&page=' + el.dataset.resourcesPage;
  resourcesUrlCallCounts[resourcesUrl] = resourcesUrlCallCounts[resourcesUrl] || 0;
  if (resourcesUrlCallCounts[resourcesUrl]++ > 0) {
    return;
  }
  createAPI().send(
    resourcesUrl,
    el.dataset.resourcesMethod,
    el.dataset.resourcesAuth,
    el.dataset.resourcesBody,
    function() {
      el.querySelector('.more-search-results') && el.querySelector('.more-search-results').classList.add('is-loading');
    },
    function (response) {
      if (el.dataset.resourcesCache) {
        // sessionStorage を使ったキャッシュライト
        const storageId = el.id || el.dataset.storageId;
        const newCache = {
          limit: el.dataset.resourcesLimit,
          page: el.dataset.resourcesPage,
          response: {data: [...response.data]},
        };
        if (newCache.page > 1 && sessionStorage.getItem(storageId)) { // 2ページ目以降だったら追加
          const cache = JSON.parse(decodeURIComponent(sessionStorage.getItem(storageId)));
          cache.response.data.push(...newCache.response.data);
          newCache.response.data = cache.response.data;
        }
        sessionStorage.setItem(storageId, encodeURIComponent(JSON.stringify(newCache)));
      }
      success(el, response);
    }
  );
}

async function searchResources(elements) {
  const lazy = {};
  if (elements.length > 0) {
    elements.forEach(el => {
      const $more = el.querySelector('.more-search-results');
      $more && $more.addEventListener('click', (event) => {
        event.preventDefault();
        event.currentTarget.blur();
        _searchResources(el);
      });
      // sessionStorage を使ったキャッシュリード
      const storageId = el.id || el.dataset.storageId;
      if (
        // キャッシュ 設定が有効
        el.dataset.resourcesCache &&
        // 以前表示したことがある
        (!el.dataset.resourcesState || document.body.dataset.state === JSON.stringify({value: el.dataset.resourcesState})) &&
        // ブラウザバック実行済みではない（safari用）
        !sessionStorage.getItem('popstate') &&
        // ロード済みページが現在のページではない（リロードにはキャッシュを使用しない）
        (!sessionStorage.getItem('load') || sessionStorage.getItem('load') !== location.pathname) &&
        // キャッシュがある
        sessionStorage.getItem(storageId)
      ) {
        console.log(storageId + ' are loaded from cache.');
        const cache = JSON.parse(decodeURIComponent(sessionStorage.getItem(storageId)));
        el.dataset.resourcesLimit = cache.limit;
        el.dataset.resourcesPage = cache.page;
        success(el, cache.response);
      } else {
        const reg = new RegExp(el.dataset.resourcesState ? '/' + el.dataset.resourcesState + '$' : location.pathname);
        if (location.pathname.match(reg)) {
          // 現在表示中
          sessionStorage.getItem('popstate') && sessionStorage.removeItem('popstate'); // ブラウザバック実行済み判定のクリア（safari用）
          _searchResources(el); // 直ちに実行
        } else {
          // 現在非表示のタブ
          if (!(el.dataset.resourcesState in lazy)) {
            lazy[el.dataset.resourcesState] = [];
          }
          lazy[el.dataset.resourcesState].push(() => _searchResources(el));
        }
      }
    });
  }
  return lazy;
}
