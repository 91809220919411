export {createAdviserRow};

function createAdviserRow(adviser, options) {
  const $div = document.createElement('div');
  $div.classList.add('col-xs-6', 'col-md-4', 'box-status-inner', 'element-creators-row');
  $div.dataset.isAccepting = adviser.is_accepting ? '1' : '0';
  $div.dataset.isClipped = adviser.is_clipped ? '1' : '0';
  $div.dataset.adviserType = adviser.adviser_type + '';
  $div.dataset.withinDays = adviser.current_within_days + '';
  $div.dataset.score = adviser.public_reviews_score + '';
  $div.dataset.answersCount = adviser.current_monthly_questions_answered_count + '';
  {
    const $a = document.createElement('a');
    $a.href = options.url.replace('000', adviser.id);
    {
      {
        const $heart = document.createElement('span');
        $heart.classList.add('babysymbol', 'icon-favorite');
        if (adviser.is_clipped) {
          $heart.classList.add('babysymbol-heart');
        } else {
          $heart.classList.add('babysymbol-heartblank');
        }
        $a.appendChild($heart);
      }
      {
        const $around = document.createElement('div');
        $around.classList.add('gutter-around');
        if (adviser.portrait_path_full) {
          const $img = document.createElement('img');
          $img.classList.add('is-rounded', 'mb5');
          $img.src = adviser.portrait_path_full.replace('/crop/80x80/', '/crop/200x200/');
          $img.width = 100;
          $img.height = 100;
          $around.appendChild($img);
        } else {
          const $img = document.createElement('span');
          $img.classList.add('has-rounded', 'display-ib', 'bgc-gray');
          $img.style.width = '100px';
          $img.style.height = '100px';
          $around.appendChild($img);
        }
        {
          const $title = document.createElement('p');
          const $span = document.createElement('span');
          $span.classList.add('ico-circle', 'c-' + adviser.adviser_type);
          $span.textContent = adviser.adviser_type_label;
          $title.appendChild($span);
          $around.appendChild($title);
        }
        {
          const $name = document.createElement('h4');
          $name.classList.add('title', 'is-4');
          $name.textContent = adviser.name;
          $around.appendChild($name);
        }
        {
          const $stars = document.createElement('div');
          $stars.classList.add('box-status-stars-wrap');
          if (options.displayType === 'B') {
            const $p = document.createElement('p');
            $p.classList.add('fw-bold');
            $p.textContent = '回答満足度';
            $stars.appendChild($p);
          }
          if (adviser.public_reviews_count == parseInt(adviser.public_reviews_count)) {

            for (let i = 500; i <= 4500; i = i + 1000) {
              const $span = document.createElement('span');
              $span.classList.add('icon-stars');
              if(i === 4500 && adviser.public_reviews_score >= i){
                if(adviser.public_reviews_score >= 5000){
                  $span.classList.add('icon-stars-y');
                }else{
                  $span.classList.add('icon-stars-yg');
                }
              } else if (adviser.public_reviews_score >= i || i === 500) {
                $span.classList.add('icon-stars-y');
              } else {
                $span.classList.add('icon-stars-g');
              }
              $stars.appendChild($span);
            }
            if (options.displayType === 'B') {
              const $span = document.createElement('span');
              $span.textContent = '（' + adviser.public_reviews_count + '）';
              $stars.appendChild($span);
            }
          } else {
            const $span = document.createElement('span');
            $span.textContent = '（' + adviser.public_reviews_count + '）';
            $stars.appendChild($span);
          }
          $around.appendChild($stars);
        }
        if (options.displayType === 'A') {
          {
            // 相談カテゴリー
            const $categories = document.createElement('div');
            $categories.classList.add('box-status-category', 'text-left');
            adviser.topics.forEach(topic => {
              if ($categories.textContent.length > 0) {
                $categories.textContent += '、';
              }
              $categories.textContent += topic.name;
            });
            if ($categories.textContent.length > 30) {
              $categories.textContent = $categories.textContent.substring(0, 30) + '...';
            }
            $around.appendChild($categories);
          }
          {
            // 受付状態
            const $status = document.createElement('div');
            $status.classList.add('box-status-limit');
            {
              const $header = document.createElement('div');
              $header.classList.add('box-status-limit-header');
              if (adviser.is_accepting) {
                if (adviser.current_within_days) {
                  $header.textContent = adviser.current_within_days + '日以内に回答';
                } else {
                  $header.textContent = '--日以内に回答';
                }
              } else {
                $header.textContent = '受付停止中';
              }
              $status.appendChild($header);
            }
            {
              const $body = document.createElement('div');
              $body.classList.add('box-status-limit-body');
              $body.textContent = '今月の回答数：' + adviser.current_monthly_questions_answered_count + '件';
              $status.appendChild($body);
            }
            $around.appendChild($status);
          }
        }
        $a.appendChild($around);
      }
      {
        const $button = document.createElement('div');
        $button.classList.add('button', 'is-fullsize', 'is-primary');
        if (adviser.is_accepting) {
          $button.classList.add('is-accepting');
          const $text = document.createElement('span');
          $text.textContent = 'プロフィールを見て相談する';
          $button.appendChild($text);
        } else {
          const $text = document.createElement('span');
          $text.textContent = 'プロフィールを見る';
          $button.appendChild($text);
        }
        {
          const $arrow = document.createElement('span');
          $arrow.classList.add('babysymbol', 'babysymbol-arrowright');
          $button.appendChild($arrow);
        }
        $a.appendChild($button);
      }
    }
    $div.appendChild($a);
  }
  return $div;
}
