import createAPI from './modules/APIRequest';
import {searchResources} from "./modules/searchResources";

document.addEventListener('DOMContentLoaded', () => {
  Array.prototype.slice.call(document.querySelectorAll('.js-get-questions-unread-count'), 0).forEach(el => {
    createAPI().send(
      el.dataset.unreadUrl,
      'GET',
      '',
      null,
      function() {},
      function (response) {
        const count = response.data.questions_unread_count | 0;
        count > 0 && el.classList.add(el.dataset.unreadClass);
        el.querySelector('.questions-unread-count').textContent = el.dataset.unreadFormat.replace('%s', count + '');
      }
    );
  });

  searchResources(Array.prototype.slice.call(document.querySelectorAll('.js-search-resources'), 0));
});
