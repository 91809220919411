export {createFrontendSPTopQuestionRow};

const monthNames = ['1','2','3','4','5','6','7','8','9','10','11','12'];
function formatDate(date) {
  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return year + '/' + monthNames[monthIndex] + '/' + day + ' ' + hours + ':' + ('0' + minutes).slice(-2);
}

function createFrontendSPTopQuestionRow(question, options) {
  const $row = document.createElement('li');
  $row.id = 'question-' + question.id;
  $row.classList.add('element-creators-row');
  {
    const $a = document.createElement('a');
    $a.href = options.url.replace('000', question.id);
    {
      const $span = document.createElement('span');
      $span.classList.add('list_icon', 'ico_article_talk');
      $a.appendChild($span);
    }
    {
      const $content = document.createElement('div');
      $content.classList.add('list_contents');
      {
        const $headline = document.createElement('p');
        $headline.classList.add('list_title');
        $headline.textContent = question.title;
        $content.appendChild($headline);
      }
      {
        const $text = document.createElement('div');
        $text.classList.add('list_text', 'clamp2');
        $text.textContent = question.body;
        $content.appendChild($text);
      }
      {
        const $block = document.createElement('div');
        $block.classList.add('block_flex', 'flex_between', 'mtb5');
        {
          const $username = document.createElement('span');
          $username.classList.add('list_username');
          $username.textContent = question.member_profile.nickname + ' ';
          $block.appendChild($username);
        }
        {
          const $span = document.createElement('span');
          $span.classList.add('list_date');
          $span.textContent = formatDate(new Date(question.posted));
          $block.appendChild($span);
        }
        $content.appendChild($block);
      }
      {
        const $block = document.createElement('div');
        $block.classList.add('list_badge', 'text_right');
        {
          const $span = document.createElement('span');
          $span.classList.add('badge_view');
          $span.textContent = question.page_views_count + ' ';
          $block.appendChild($span);
        }
        {
          const $span = document.createElement('span');
          $span.classList.add('badge_star');
          $span.textContent = question.comments_likes_count + '';
          $block.appendChild($span);
        }
        $content.appendChild($block);
      }
      $a.appendChild($content);
    }
    $row.appendChild($a);
  }
  return $row;
}
