export {createQuestionRow};

const monthNames = ['1','2','3','4','5','6','7','8','9','10','11','12'];
function formatDate(date) {
  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return year + '/' + monthNames[monthIndex] + '/' + day + ' ' + hours + ':' + ('0' + minutes).slice(-2);
}

function createQuestionRow(question, options) {
  const $div = document.createElement('div');
  $div.classList.add('col-xs-12', 'col-md-6', 'element-creators-row');
  {
    const $a = document.createElement('a');
    $a.href = options.url.replace('000', question.id);
    question.resolved && $a.classList.add('sta-solve');
    {
      const $top = document.createElement('div');
      $top.classList.add('box-top', 'row');
      {
        // ユーザーアイコン
        const $element = document.createElement('div');
        $element.classList.add('col-xs-2');
        const $img = document.createElement('img');
        $img.classList.add('is-rounded', 'mb5');
        $img.setAttribute('width', '40');
        $img.setAttribute('height', '40');
        $img.src = question.member_profile.portrait_path_full;
        $element.appendChild($img);
        $top.appendChild($element);
      }
      {
        // ユーザーアイコンの右側
        const $element = document.createElement('div');
        $element.classList.add('col-xs-10');
        {
          const $element_top = document.createElement('div');
          $element_top.classList.add('row');
          {
            const $element_top_left = document.createElement('div');
            $element_top_left.classList.add('col-xs-6', 'text-elps');
            {
              // ユーザーニックネーム
              const $span = document.createElement('span');
              $span.textContent = question.member_profile.nickname;
              $element_top_left.appendChild($span);
            }
            $element_top.appendChild($element_top_left);
          }
          {
            // 投稿日時
            const $element_top_right = document.createElement('div');
            $element_top_right.classList.add('col-xs-6', 'text-right');
            const $span = document.createElement('span');
            $span.classList.add('c-pink');
            $span.textContent = formatDate(new Date(question.posted));
            $element_top_right.appendChild($span);
            $element_top.appendChild($element_top_right);
          }
          $element.appendChild($element_top);
        }
        {
          const $element_bottom = document.createElement('div');
          $element_bottom.classList.add('row');
          {
            const $element_bottom_left = document.createElement('div');
            $element_bottom_left.classList.add('col-xs', 'text-elps');
            {
              // シンボル
              for (let i = 0; i < question.state_at_posted.length; i++) {
                const $symbol = document.createElement('span');
                $symbol.classList.add('babysymbol', 'fz-i', 'c-pink');
                if (
                  question.state_at_posted[i].key === 'child_na_birthday' ||
                  question.state_at_posted[i].key === 'child_boy_birthday' ||
                  question.state_at_posted[i].key === 'child_girl_birthday'
                ) {
                  $symbol.classList.add('babysymbol-baby');
                } else if (question.state_at_posted[i].key === 'estimated_date_of_delivery') {
                  $symbol.classList.add('babysymbol-maternity');
                }
                $element_bottom_left.appendChild($symbol);
                // 月齢または妊娠週数
                const $meta = document.createElement('span');
                $meta.classList.add('mr5');
                $meta.textContent = ' ' + question.state_at_posted[i].state;
                $element_bottom_left.appendChild($meta);
              }
            }
            $element_bottom.appendChild($element_bottom_left);
          }
          if (options.displayType === 'A') {
            // 閲覧数
            $element_bottom.appendChild(document.createElement('br'));
            const $element_bottom_right = document.createElement('div');
            {
              $element_bottom_right.classList.add('col-xs-2', 'text-right');
              const $eye = document.createElement('span');
              $eye.classList.add('babysymbol', 'babysymbol-view', 'fz-i', 'mr5');
              $element_bottom_right.appendChild($eye);
              const $span = document.createElement('span');
              $span.textContent = question.page_views_count + '';
              $element_bottom_right.appendChild($span);
            }
            $element_bottom.appendChild($element_bottom_right);
          } else if (options.displayType === 'B') {
            //
          }
          $element.appendChild($element_bottom);
        }
        $top.appendChild($element);
      }
      $a.appendChild($top);
    }
    {
      const $middle = document.createElement('div');
      $middle.classList.add('box-middle');
      {
        // タイトル
        const $element = document.createElement('p');
        $element.classList.add('fw-bold', 'text-elps');
        $element.textContent = question.title;
        $middle.appendChild($element);
      }
      {
        // 本文
        const $element = document.createElement('p');
        $element.classList.add('mb10');
        $element.style.wordBreak = 'break-all';
        $element.textContent = question.body;
        if ($element.textContent.length > 100) {
          $element.textContent = $element.textContent.substring(0, 100) + '...';
        }
        $middle.appendChild($element);
      }
      $a.appendChild($middle);
    }
    {
      const $bottom = document.createElement('div');
      $bottom.classList.add('box-bottom', 'row', 'between-xs');
      {
        const $element = document.createElement('div');
        $element.classList.add('col-xs-6');
        if (options.displayType === 'A') {
          // 相談ステータスラベル
          if (question.status === 0) {
            const $div = document.createElement('div');
            $div.classList.add('label-answer');
            $div.textContent = '専門家回答待ち';
            $element.appendChild($div);
          } else if (question.status === 4) {
            const $div = document.createElement('div');
            $div.classList.add('label-answer');
            $div.textContent = '非表示';
            $element.appendChild($div);
          } else {
            const $div = document.createElement('div');
            $div.classList.add('label-answer', 'label-answer-hasa');
            $div.textContent = '専門家回答あり';
            const $heart = document.createElement('span');
            $heart.classList.add('babysymbol', 'babysymbol-heart');
            $heart.textContent = question.comments_likes_count + '';
            $div.appendChild($heart);
            $element.appendChild($div);
          }
        } else if (options.displayType === 'B') {
          // いいね数
          const $heart = document.createElement('span');
          $heart.classList.add('babysymbol', 'babysymbol-heart', 'c-pink');
          $heart.textContent = question.comments_likes_count + '';
          $element.appendChild($heart);
          // 閲覧数
          const $eye = document.createElement('span');
          $eye.classList.add('babysymbol', 'babysymbol-view');
          $eye.textContent = question.page_views_count + '';
          $element.appendChild($eye);
        }
        $bottom.appendChild($element);
      }
      if (question.topic_name.length > 0) {
        // カテゴリーネーム
        const $element = document.createElement('div');
        $element.classList.add('col-xs-6', 'text-right');
        const $span = document.createElement('span');
        $span.classList.add('label-round', 'is-palepink');
        $span.textContent = question.topic_name;
        $element.appendChild($span);
        $bottom.appendChild($element);
      }
      $a.appendChild($bottom);
    }
    $div.appendChild($a);
  }
  return $div;
}
